import { message } from "antd";
import axios from "axios";
import { Bindungsarten, RechtsformID } from "../pages/Customer/Customer";
import { Bindung, Kunde, LookupLists, lookup } from "../services/api-types";
import { BindungId, FrontendId } from "./ui-ids";

// TODO this should not be hard-coded but taken from lookup list
export const GENDER_FEMALE = 2;


/**
 * Type guard  for type-safe removal of missing values with filters
 *
 * Example:
 *
 * ```ts
 * const a: number[] = [2, null, 3, undefined].filter(isPresent);
 * ```
 *
 * @param v
 * @returns false if v is undefined or null, true in all other cases
 */
export function isPresent<T>(v: T | undefined | null): v is T {
    return v !== undefined && v !== null;
}

export function isMissing<T>(v: T | undefined | null): v is undefined | null {
    return v === undefined || v === null;
}

/**
 * logical implication "a implies b" the same as "(not a) or b"
 */
export const implies = (a: boolean, b: boolean) => (!a || b)

/**
 * Check for existing string, an empty string does not count.
 *
 * @param v
 * @returns true if string available and not empty
 */
export function hasText(v: string | undefined | null): v is string {
    return !!v && v !== '';
}

/**
 * Check for missing string, either null, undefined or empty
 *
 * @param v
 * @returns true if v is not a non-empty string
 */
export function noText(v: string | undefined | null): boolean {
    return !hasText(v);
}

/**
 * Check for existing string, an empty string does not count.
 *
 * @param v
 * @returns true if string available and not empty
 */
export function hasElems<T>(v: Array<T> | undefined | null): v is Array<T> {
    return !!v && v.length > 0;
}

/**
 * Check for missing string, either null, undefined or empty
 *
 * @param v
 * @returns true if v is not a non-empty string
 */
export function noElems<T>(v: Array<T> | undefined | null): boolean {
    return !hasElems(v);
}

/**
 * Creates a new array with a specified value between each element of the provided array.
 *
 * @param {Array<T>} array - The array of elements to intersperse with the value.
 * @param {T} value - The value to insert between the elements of the array.
 * @returns {Array<T>} A new array with the specified value inserted between each element pair.
 */
export const intersperse = <T,> (array: T[], value: T): (T | number)[] =>
    array.reduce((acc,v,i)=>(i && acc.push(value), acc.push(v), acc), [] as Array<T>)

/**
 * Create a copy of an object containing only fields the values of which match the given predicate
 * @param obj the object that is to be copied
 * @param predicate the predicate that all values of the resulting object must satisfy
 * @returns a copy of obj containing only fields with values that satisfy predicate
 */
export const filterValues = (obj: object, predicate: ((o: any) => boolean)) => {
    const res: any = {}
    Object.entries(obj).forEach(([key, value]) => {
        if (predicate(value)) {
            // workaround for bug https://github.com/microsoft/TypeScript/issues/32327
            if (key === '__proto__') {
                Object.defineProperty(res, key, {
                    'configurable': true,
                    'enumerable': true,
                    'value': value,
                    'writable': true
                })
            } else {
                res[key] = value
            }
        }
    })
    return res;
}



/**
 * create an array with consecutive numbers.
 *
 * If toExclusive <= fromInclusive, the array will be empty.
 *
 * @param fromInclusive the first number of the resulting array
 * @param toExclusive the first number > fromInclusive that is not included in the array.
 * @returns
 */
export const range = (fromInclusive: number, toExclusive: number): number[] =>
    (toExclusive < fromInclusive)
        ? []
        : Array.from({ length: toExclusive - fromInclusive }, (v, i) => i + fromInclusive);


type ComparisonResult = -1 | 0 | 1;
type Comparer<T> = (a: T, b: T) => ComparisonResult
type SafeComparable = number | string | number[] | string[];

export const standardCompare = <T extends SafeComparable>(a: T, b: T) => {
    if (typeof a === 'string' && typeof b === 'string') {
        return a.localeCompare(b) as ComparisonResult;
    }
    if (a < b) { return -1 }
    if (a > b) { return 1 }
    return 0;
}

export const compareByKey = <T, K extends SafeComparable>
    (toKey: (v: T) => K): Comparer<T> => (a, b) => standardCompare(toKey(a), toKey(b));


/**
 *
 * check if source string includes target string, ignore cases
 *
 * @param target target string
 * @param source source string, undefined possible
 * @returns if source string includes target string return true, otherwise false
 */

export const hasInclude = (target: string, source?: string): boolean => {
    return source?.toLowerCase().includes(target.toLowerCase()) || false;
}

/**
 * equality for object types with properties that have types with natural equality.
 * Two values are deemed equal if they have the same properties defined and their
 * values are equal by `===`
 *
 * @param a
 * @param b
 * @returns true if objects are deemed equal
 */
export function equalByProps<T extends {}>(a: T, b: T): boolean {
    const keysA = Object.keys(a) as (keyof T)[];
    const keysB = Object.keys(b) as (keyof T)[];
    return keysA.length === keysB.length && keysA.every(key => key in b && a[key] === b[key]);
}


/**
 * display error message with ui and in console
 *
 * @param error
 */
export const errorMessage = (error: unknown) => {
    console.error(error);
    let errorMessage = '';
    if (axios.isAxiosError(error)) {
        errorMessage = `${error.response?.data.message}`;
    } else {
        errorMessage = `${error}`;
    }
    message.error(`Oooops, Es ist irgendwie schief gelaufen. Error: ${errorMessage}`);
}

/**
 * handle error response, cancelled ajax request will not be represented, but it will be logged in console.
 *
 * @param error
 */

export const handleErrorResponse = (error: unknown) => {
    if (axios.isCancel(error)) {
        console.warn('Abfrage ist gecancelt worden.');
    }
    else {
        errorMessage(`Oooops, Es ist irgendwie schief gelaufen. Error: ${error}`);
    }
}


/**
 *
 * display data loss warning prompt before unloading the site
 *
 * @param event
 * @returns data loss warning
 */

export const handleBeforeunloadEvent = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    return event.returnValue = 'Deine Änderungen werden eventuell nicht gespeichert.'
};


/**
 *
 * normalize whitespace in given string
 *
 * @param string
 * @returns the value of given string with leading and trailing whitespace removed, and sequences of internal whitespace reduced to a single whitespace
 */
export const normalizeSpace = (string: string) => {
    return string.replace(/\s+/g, ' ').trim();
};

/**
 *
 * @param value number
 * @returns if the given number exists, return the number, else return 0
 */

export const numberOrZero = (value?: number) => {
    return value ?? 0;
}

/**
 *
 * @param list list of objects
 * @param getKey the key to group objects
 * @returns grouped record
 */

export const groupBy = <T, K extends keyof T>(list: T[], key: K) =>
    list.reduce((previous, currentItem) => {
        const group = currentItem[key] as string;
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
    }, {} as Record<string, T[]>);


//TODO: here is not the right file for this function
export function updateBindungsarten(person1: Kunde, person2: Kunde, lookupLists: LookupLists): lookup.Bindungsart[] {
    const rechtsformSource = lookupLists.rechtsformList.find(rechtsform => rechtsform.id === person1.rechtsform)
    const rechtsformTarget = lookupLists.rechtsformList.find(rechtsform => rechtsform.id === person2.rechtsform)
    let finalBindungsart: lookup.Bindungsart[] = lookupLists.bindungsartList;
    if (rechtsformSource && rechtsformTarget) {
        if ((rechtsformSource.id === RechtsformID.EHE || rechtsformSource.id === RechtsformID.WOHNGEMEINSCHAFT) && rechtsformTarget.id === RechtsformID.PRIVATPERSON) {
            return finalBindungsart.filter(ba => ba.id !== Bindungsarten.VERTRAGSTEILNEHMER);
        } else if ((rechtsformTarget.id === RechtsformID.EHE || rechtsformTarget.id === RechtsformID.WOHNGEMEINSCHAFT) && rechtsformSource.id === RechtsformID.PRIVATPERSON) {
            return finalBindungsart.filter(ba => ba.id !== Bindungsarten.GEMEINSAMER_VERTRAGSPARTNER);
        } else {
            return finalBindungsart.filter(ba => ba.id !== Bindungsarten.VERTRAGSTEILNEHMER && ba.id !== Bindungsarten.GEMEINSAMER_VERTRAGSPARTNER);
        }
    } else {
        return finalBindungsart.filter(ba => ba.id !== Bindungsarten.VERTRAGSTEILNEHMER && ba.id !== Bindungsarten.GEMEINSAMER_VERTRAGSPARTNER);
    }
}

//TODO: here is not the right file for this function

export function createNameOfGmeinsamerPerson(mainPerson: Kunde, associatedPerson: Kunde): string {
    const bothHaveGivenNames = mainPerson.vorname && associatedPerson.vorname;
    const persons = [mainPerson, associatedPerson].sort(compareByKey(p => [
        p.geschlecht === GENDER_FEMALE ? 'A' : 'B',
        bothHaveGivenNames ? p.vorname ?? '' : '',
        p.name
    ])) as [Kunde, Kunde];
    return formatNameOfGemeinsamerPerson(...persons);
}

function formatNameOfGemeinsamerPerson(person1: Kunde, person2: Kunde): string {
    if (person1.vorname && person2.vorname) {
        if (person1.name.toLowerCase().trim() === person2.name.toLowerCase().trim()) {
            return normalizeSpace(`${person1.vorname} und ${person2.vorname} ${person1.name}`);
        } else {
            return normalizeSpace(`${person1.vorname} ${person1.name} und ${person2.vorname} ${person2.name}`);
        }
    } else {
        if (person1.name.toLowerCase().trim() === person2.name.toLowerCase().trim()) {
            return normalizeSpace(`Familie ${person1.name}`);
        } else {
            return normalizeSpace(`Familie ${person1.name} und ${person2.name}`);
        }
    }
}
